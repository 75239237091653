import { Cms } from "@/src/utils";
import { ISearch } from "./types";

const RES_KEY = "/search";

export const getSearch = async ({
  slug,
  locale,
}: {
  slug?: string;
  locale?: string;
}): Promise<ISearch> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY, {
    populate: "deep,4",
    locale
  });
};
