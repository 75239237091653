"use client";
import { IBook } from "@/src/lib";
import clsx from "clsx";
import Image from "next/image";
import { BookTitle } from "./BookTitle";
import dynamic from "next/dynamic";
import type { FC, ReactNode } from "react";

const FavoriteButton = dynamic(
  () => import("./FavoriteButton").then((m) => m.FavoriteButton),
  { ssr: false }
);

interface Props {
  book: IBook;
  search?: string;
  isSearch?: boolean;
  bookOrientation?: "vertical" | "horizontal";
  hasFavoriteButton?: boolean;
}

const Book = ({
  book,
  search = "",
  isSearch = false,
  bookOrientation,
  hasFavoriteButton = true,
}: Props) => {
  const { title } = book;
  return (
    <BookWrapper
      isSearch={!!search || isSearch}
      bookOrientation={bookOrientation}
    >
      <div
        className={clsx(
          "relative rounded-lg overflow-hidden",
          isSearch ? "w-16 h-20" : "w-28 h-40"
        )}
      >
        {book.mainImage?.length && (
          <Image
            fill
            src={book.mainImage[0].url}
            alt={book.mainImage[0].alternativeText}
            loading="lazy"
            sizes="(max-width: 768px) 100vw, 66vw"
            className="animate-appear-1"
            quality={50}
          />
        )}
      </div>
      <div className="flex-1 flex flex-col">
        <div className="flex-1">
          <BookTitle searchText={search} initialText={title} />
        </div>
        <div>
          {book.authors[0] && (
            <div className="text-[#666666] text-xs">{book.authors[0].name}</div>
          )}
          {book.price && (
            <div className="font-medium">
              {book.price} {book.currency}
            </div>
          )}
          {hasFavoriteButton && <FavoriteButton book={book} />}
        </div>
      </div>
    </BookWrapper>
  );
};

interface IBookWrapper {
  children: ReactNode;
  isSearch?: boolean;
  bookOrientation?: "vertical" | "horizontal";
}

const BookWrapper: FC<IBookWrapper> = ({
  children,
  isSearch,
  bookOrientation,
}) => {
  return (
    <div
      className={clsx(
        "flex h-full",
        isSearch
          ? "items-center gap-8"
          : bookOrientation === "vertical"
          ? "flex-col gap-3 max-w-36"
          : "flex-col sm:flex-row sm:items-center sm:gap-8"
      )}
    >
      {children}
    </div>
  );
};

const Skeleton: FC<Omit<IBookWrapper, "children">> = ({ isSearch }) => {
  return (
    <BookWrapper isSearch={isSearch}>
      <div
        className={clsx(
          "bg-primary relative rounded-lg overflow-hidden mb-2 opacity-10 animate-pulse",
          isSearch ? "w-16 h-20" : "w-28 h-40"
        )}
      ></div>
      <div className="animate animate-pulse bg-slate-300 w-40 rounded-lg h-5"></div>
    </BookWrapper>
  );
};

Book.Skeleton = Skeleton;
export { Book };
