import { type IBook } from "@/src/lib";
import clsx from "clsx";
import { Book } from "./Book";
import { NoBooks } from "../common";

interface Props {
  books: IBook[] | undefined;
  isLoading: boolean;
  isError: boolean;
  bookOrientation?: "vertical" | "horizontal";
}

const BookList: React.FC<Props> = ({
  books,
  isLoading,
  bookOrientation = "horizontal",
}) => {
  if (isLoading) {
    return (
      <div className="grid grid-cols-2 lg:grid-cols-3 justify-between gap-8 mt-5">
        <Book.Skeleton />;
      </div>
    );
  }

  if (!books?.length) {
    return <NoBooks />;
  }

  return (
    <div
      className={clsx(
        "grid grid-cols-2 justify-between gap-8 mt-5",
        bookOrientation === "vertical"
          ? "sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6"
          : "xs:grid-cols-3 md:grid-cols-3"
      )}
    >
      {books?.map((book) => {
        return (
          <Book
            key={book.id}
            book={book}
            bookOrientation={bookOrientation}
            hasFavoriteButton={false}
          />
        );
      })}
    </div>
  );
};

export default BookList;
