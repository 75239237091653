"use client";
import type { IBook } from "@/src/lib";
import { addBookToLocalStorage, checkIsBookInLocalStorage } from "@/src/utils";
import { HeartIcon as FavoritesIcon } from "@heroicons/react/24/outline";
import { HeartIcon as FavoritesSelectedIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { type FC, useEffect, useState } from "react";

interface Props {
  book: IBook;
}

export const FavoriteButton: FC<Props> = ({ book }) => {
  const [savedButtonVisible, setSavedButtonVisible] = useState(
    checkIsBookInLocalStorage(book.titleIdentificator)
  );

  useEffect(() => {
    const listenStorageChange = () => {
      const isBookSaved = checkIsBookInLocalStorage(book.titleIdentificator);
      setSavedButtonVisible(Boolean(isBookSaved));
    };

    if (typeof window !== "undefined") {
      window.addEventListener("storage", listenStorageChange);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("storage", listenStorageChange);
      }
    };
  }, [book]);

  return (
    <button
      className="relative w-7 h-7"
      onClick={async (e) => {
        e.preventDefault();
        addBookToLocalStorage(book.titleIdentificator);
        if (typeof window !== "undefined") {
          window.dispatchEvent(new Event("storage"));
        }
      }}
    >
      <span className="sr-only">favorite button</span>
      <FavoritesIcon
        color="#F4AD22"
        width={28}
        height={28}
        className="absolute top-0"
      />
      <FavoritesSelectedIcon
        color="#F4AD22"
        width={28}
        height={28}
        className={clsx(
          "absolute top-0 z-10 transition-all duration-300",
          savedButtonVisible ? "opacity-100" : "opacity-0 hover:opacity-100"
        )}
      />
    </button>
  );
};
