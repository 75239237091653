"use client";
import { useEffect, useState } from "react";

export const useWindowWidth = () => {
  const [isWideScreen, setIsWideScreen] = useState<boolean>(
    window.innerWidth > 768
  );

  useEffect(() => {
    if (!!window) {
      const handleResize = () => {
        setIsWideScreen(window ? window?.innerWidth > 768 : false);
      };

      window.addEventListener("resize", handleResize);

      // Call the handler immediately to set the initial value
      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  return isWideScreen;
};
