import { Cms } from "@/src/utils";
import { CmsQuery } from "@/src/utils";
import { PagedResult } from "@/src/utils";
import { IMenuItem } from "./types";

const RES_KEY = "/menu-items";

export const getMenuItems = async ({
  query,
  locale,
}: {
  query?: CmsQuery;
  locale?: string;
}): Promise<PagedResult<IMenuItem>> => {
  const client = Cms.createClient();

  return await client.fetchContentEntries(RES_KEY, {
    ...query,
    sort: ["order"],
    populate: "deep,4",
    locale: locale,
  });
};

export const getMenuItem = async ({
  slug,
  locale,
}: {
  slug?: string;
  locale?: string;
}): Promise<IMenuItem> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY, {
    filters: {
      slug: { $eq: slug },
    },
    populate: "deep,4",
    locale
  });
};
