import { Cms, CmsQuery, PagedResult } from "@/src/utils";
import { ICategory, ICategoryPageData, ICategorySinglePageData } from "./types";

const RES_KEY = "/categories";
const RES_KEY_PAGE = "/category-page";
const RES_KEY_SINGLE_PAGE = "/single-category-page";

export const getCategories = async (
  query?: CmsQuery
): Promise<PagedResult<ICategory>> => {
  const client = Cms.createClient();

  return await client.fetchContentEntries(RES_KEY, {
    ...query,
    // fields: ['title', 'backgroundColor'],
    // sort: [],
    populate: "*",
  });
};

export const getCategory = async (
  slug?: string,
  locale?: string
): Promise<ICategory> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY, {
    filters: {
      slug: { $eq: slug },
    },
    populate: "*",
    locale,
  });
};

export const getCategoryPageData = async (
  locale?: string
): Promise<ICategoryPageData> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY_PAGE, {
    filters: {
      pageId: { $eq: "category" },
    },
    populate: "*",
    locale,
  });
};

export const getSingleCategoryPageData = async (
  locale?: string
): Promise<ICategorySinglePageData> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY_SINGLE_PAGE, {
    filters: {},
    populate: "deep,2",
    locale,
  });
};
