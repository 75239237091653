"use client";
import type { Pagination } from "@/src/utils/cms/paged.result";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/navigation";
import { type FC, useCallback } from "react";

type Props = {
  pagination: Pagination;
};

export const SingleCategoryPagination: FC<Props> = ({
  pagination: { page: currentPage, total, pageCount },
}) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { replace } = useRouter();

  const createQueryString = useCallback(
    (name: string, value: string | number) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, String(value));
      return params.toString();
    },
    [searchParams]
  );

  const pageDown = useCallback(() => {
    replace(pathname + "?" + createQueryString("page", currentPage - 1));
  }, [currentPage, createQueryString, pathname, replace]);

  const pageUp = useCallback(() => {
    replace(pathname + "?" + createQueryString("page", currentPage + 1));
  }, [currentPage, createQueryString, pathname, replace]);

  return (
    <div className="w-full flex justify-end items-end gap-3">
      <div className="flex gap-1 font-medium text-[#954F54]">
        <span> {currentPage > total ? 1 : currentPage}</span>
        <span>/</span>
        <span>{pageCount}</span>
      </div>
      <div className="flex flex-col">
        <button
          onClick={pageDown}
          disabled={currentPage <= 1}
          className="bg-white flex justify-center items-center w-8 h-8"
        >
          <ChevronLeftIcon width={20} height={20} />
          <span className="sr-only">page down</span>
        </button>
        <button
          onClick={pageUp}
          disabled={pageCount <= currentPage}
          className="bg-white flex justify-center items-center w-8 h-8"
        >
          <ChevronRightIcon width={20} height={20} />
          <span className="sr-only">page up</span>
        </button>
      </div>
    </div>
  );
};
