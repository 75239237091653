"use client";
import type { IBook, IStory } from "@/src/lib";
import {
  type FC,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import { StoryPageHeader } from "../story";
import { BookPageHeader } from "../book";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import { useWindowWidth } from "@/src/utils";

const Content = lazy(() =>
  import("../content").then((m) => ({ default: m.Content }))
);

type Props = {
  story: IStory;
  book: IBook;
  mainSide?: "story" | "book";
  lng: string;
};

export const StoryBookContent: FC<Props> = ({ story, book, mainSide, lng }) => {
  const [isCenter, setIsCenter] = useState(true);

  const { push, prefetch } = useRouter();
  const isWideScreen = useWindowWidth();

  const isStoryMainSide = mainSide === "story";
  const isBookMainSide = mainSide === "book";

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const linkHandler = useCallback(() => {
    scrollToTop();
    setIsCenter(true);
    if (isBookMainSide) {
      prefetch(`/story/${story.titleIdentificator}`);
    } else {
      prefetch(`/book/${book.titleIdentificator}`);
    }
    setTimeout(() => {
      if (isBookMainSide) {
        push(`/story/${story.titleIdentificator}`);
      } else {
        push(`/book/${book.titleIdentificator}`);
      }
    }, 800);
  }, [
    isBookMainSide,
    scrollToTop,
    push,
    prefetch,
    story.titleIdentificator,
    book.titleIdentificator,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCenter(false);
    }, 400);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex w-full">
      <div
        className={clsx(
          "w-full transition-all duration-700",
          isBookMainSide ? "hidden md:block" : "",
          isCenter || !mainSide
            ? "md:w-1/2"
            : isStoryMainSide
            ? "md:w-2/3"
            : "md:w-1/3"
        )}
      >
        <div className={clsx(isBookMainSide ? "sticky top-[112px]" : "")}>
          {isStoryMainSide ? (
            <>
              <StoryPageHeader story={story} lng={lng} />
              <Suspense fallback={<>Loading content...</>}>
                <Content content={story.content} />
              </Suspense>
            </>
          ) : isWideScreen ? (
            <div onClick={linkHandler} className="cursor-pointer">
              <StoryPageHeader story={story} lng={lng} bookView />
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={clsx(
          "w-full transition-all duration-700",
          isStoryMainSide ? "hidden md:block" : "",
          isCenter || !mainSide
            ? "md:w-1/2"
            : isBookMainSide
            ? "md:w-2/3"
            : "md:w-1/3"
        )}
      >
        <div className={clsx(isStoryMainSide ? "sticky top-[112px]" : "")}>
          {isBookMainSide ? (
            <>
              <BookPageHeader book={book} lng={lng} />
              <Suspense fallback={<>Loading content...</>}>
                <Content content={book.content} />
              </Suspense>
            </>
          ) : isWideScreen ? (
            <div onClick={linkHandler} className="cursor-pointer">
              <BookPageHeader
                book={book}
                lng={lng}
                storyView={isStoryMainSide || !mainSide}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
