import { Cms } from "@/src/utils";
import { IDonations } from "./types";

const RES_KEY_PAGE = "/donations";

export const getDonationsData = async (locale: string): Promise<IDonations> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY_PAGE, {
    populate: "*",
    locale,
  });
};
