import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/useMutation.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/useQueries.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/useQuery.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/app/[lng]/providers.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/app/globals.css");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lng]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/assets/logo.png");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/book/Book.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/book/BookPageHeader.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/book/BookTitle.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/book/FavoriteButton.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/category/SingleCategoryPagination.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/category/slider/CategorySlider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/common/HomeSwiper/HomeSwiper.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/common/LanguageSwitch.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/common/Modal.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/common/Navbar/Desktop/DesktopMenuItem.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/common/Navbar/Mobile/MobileMenu.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/common/RelatedBooks.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/common/Search/NavbarSearch/Mobile.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/common/Search/NavbarSearch/Search.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/common/Search/PageSearch.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/common/StoryBookContent.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/contact/Form.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/contact/SendQuery.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/content/Content.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/content/Iframe.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/content/Media.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/content/Slider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/components/hashtag/SearchSection.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/lib/book/queries.ts");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/lib/hashtag/queries.ts");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/utils/hooks/useWindowWidth.ts");
import(/* webpackMode: "eager" */ "/home/runner/work/frontend/frontend/src/utils/localStorage/localStorage.ts")