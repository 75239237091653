"use client";
import { ComponentName, ContentComponent, ContentType } from "@/src/types";
import dynamic from "next/dynamic";
import { ComponentType, type FC } from "react";

const Iframe = dynamic(() => import("./Iframe").then((m) => m.Iframe));
const ImageText = dynamic(() => import("./ImageText").then((m) => m.ImageText));
const Media = dynamic(() => import("./Media").then((m) => m.Media));
const RichText = dynamic(() => import("./RichText").then((m) => m.RichText));
const Slider = dynamic(() => import("./Slider").then((m) => m.Slider));

type Props = {
  content: ContentType[] | undefined;
};

const componentMap = {
  "shared.image-text": ImageText,
  "shared.iframe": Iframe,
  "shared.media": Media,
  "shared.rich-text": RichText,
  "shared.slider": Slider,
} as Record<ComponentName, ComponentType<ContentComponent>>;

export const Content: FC<Props> = ({ content }) => {
  return (
    <>
      {content?.map((component) => {
        const Component = componentMap[component.__component.name];

        return Component ? (
          <Component
            key={component.id + component.__component.name}
            {...component.__component}
          />
        ) : null;
      })}
    </>
  );
};
