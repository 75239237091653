import { Cms } from "@/src/utils";
import { IContactPage } from "./types";


const RES_KEY_PAGE = "/contact";

export const getContactPageData = async (locale: string): Promise<IContactPage> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY_PAGE, {
    populate: "*",
    locale,
  });
};
