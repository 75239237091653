"use client";

export const addBookToLocalStorage = (item: unknown) => {
  if (typeof window !== undefined) {
    const lsData = window.localStorage.getItem("favoriteBooks");
    const existingBooks: string[] = lsData ? JSON.parse(lsData || "") : [];
    const isBookInLs = existingBooks.filter((e) => e === item).length;

    if (!isBookInLs) {
      const newBooks = existingBooks ? [...existingBooks, item] : [item];
      window.localStorage.setItem("favoriteBooks", JSON.stringify(newBooks));
    } else {
      const newBooks = existingBooks.filter((book) => book !== item);
      window.localStorage.setItem("favoriteBooks", JSON.stringify(newBooks));
    }
  }
};

export const checkIsBookInLocalStorage = (bookIdentificator: string) => {
  if (typeof window !== undefined) {
    const lsData = window.localStorage.getItem("favoriteBooks");
    const existingBooks: string[] = lsData ? JSON.parse(lsData || "") : [];
    const isBookInLs = existingBooks.filter(
      (e) => e === bookIdentificator
    ).length;
    return Boolean(isBookInLs);
  } else return false;
};

export const getAllSelectedFromLocalStorage = () => {
  if (typeof window !== undefined) {
    const lsData = window.localStorage.getItem("favoriteBooks");
    const existingBooks: string[] = lsData ? JSON.parse(lsData || "") : [];
    return existingBooks;
  } else return [];
};
