import { Cms } from "@/src/utils";
import { IHome } from "./types";

const RES_KEY_PAGE = "/home-page";

export const getHomePageData = async (locale: string): Promise<IHome> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY_PAGE, {
    populate: "deep,4",
    locale,
  });
};
