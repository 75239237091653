import { type FC } from "react";
import Image from "next/image";
import { IStory } from "@/src/lib/story/types";
import { StoryIndicator } from "../common";

type Props = {
  story: IStory;
  lng: string;
  bookView?: boolean;
};

export const StoryPageHeader: FC<Props> = ({
  story,
  lng,
  bookView = false,
}) => {
  return (
    <div style={{ backgroundColor: story.backgroundColor }}>
      {story.mainPhoto && (
        <div className="relative w-full sb-height">
          <div
            className="absolute top-6 z-10 left-6 text-3xl font-semibold"
            style={{ color: story.mainPhotoTitleColor || "#000" }}
          >
            {story.title}
          </div>
          {bookView && <div className="absolute top-0 left-0 bottom-0 flex items-center justify-end pl-5 z-20">
             <StoryIndicator lng={lng} />
          </div>}
          <Image
            src={story.mainPhoto.url}
            alt={story.mainPhoto.alternativeText}
            fill
            style={{ objectFit: "cover" }}
            priority={true}
            loading="eager"
            quality={100}
            sizes="(max-width: 768px) 100vw, 66vw"
          />
        </div>
      )}
      {!bookView && story.subtitle && (
        <div
          className="p-6"
          dangerouslySetInnerHTML={{ __html: story.subtitle }}
          style={{ color: story.textColor }}
        ></div>
      )}
    </div>
  );
};
