import { Cms, CmsQuery, PagedResult } from "@/src/utils";
import { IStory, IStrapiStory } from "./types";
import { handleComponent } from "../handleComponent";

const RES_KEY = "/stories";

export const getStories = async (
  query?: CmsQuery
): Promise<PagedResult<IStory>> => {
  const client = Cms.createClient();

  return await client.fetchContentEntries(RES_KEY, {
    ...query,
    populate: "deep,4",
  });
};

export const getStory = async (query: CmsQuery): Promise<IStory> => {
  const client = Cms.createClient();

  const story: IStrapiStory = await client.fetchContentEntry(RES_KEY, {
    ...query,
    populate: "deep,6",
  });

  return {
    ...story,
    content: story.content?.map((item) => {
      return handleComponent(item);
    }),
  };
};
