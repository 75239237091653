"use client";
import { type FC } from "react";
import { TRow } from "@/src/lib";
import dynamic from "next/dynamic";
import { useWindowWidth } from "@/src/utils";

const HomeMobileSwiper = dynamic(
  () =>
    import("./HomeMobileSwiper").then((m) => ({ default: m.HomeMobileSwiper })),
  { ssr: false, loading: () => <>Loading...</> }
);

const HomeDesktopSwiper = dynamic(
  () =>
    import("./HomeDesktopSwiper").then((m) => ({
      default: m.HomeDesktopSwiper,
    })),
  { ssr: false, loading: () => <>Loading...</> }
);

interface Props {
  row: TRow[];
  lng: string;
}

export const HomeSwiper: FC<Props> = ({ lng, row }) => {
  const isWideScreen = useWindowWidth();

  return isWideScreen ? (
    <div className="sb-height">
      <HomeDesktopSwiper lng={lng} rows={row} />
    </div>
  ) : (
    <HomeMobileSwiper lng={lng} row={row} />
  );
};
